import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Layout from "../components/layout"
import { Jumbotron, Button, Container } from 'reactstrap'
import { navigate } from '@reach/router';

const resent = (props) => {
    return (
        <Layout>

            <Container
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}
                >
            
                <Jumbotron >
                    
                    <p className="lead">
                        {!props.location.state ? ( 
                            <font>
                                Your request has been processed. Please check your email.
                            </font>
                        ) : (props.location.state.data === 'forgot') ? (
                            <font>
                                The verification link has been sent to you. Please check your email.
                            </font>
                        ) : (props.location.state.data === 'resend') ? (
                            <font>
                                Your request has been processed. Please check your email.
                            </font>
                        ) : (
                            <font>
                                Your request has been processed. Please check your email.
                            </font>
                        )}

                        
                    </p>
                    <p className="lead">
                        <Button
                            color="primary"
                            onClick={event => {
                                event.preventDefault()
                                navigate('/')
                            }}
                        >
                            Go back home
                        </Button>
                    </p>

                </Jumbotron>
            </Container>
        </Layout>
    )
}

export default resent
